<template>
    <div class="blur-gradient-box">
      <slot></slot>
    </div>
  </template>
  

  <script setup>
  </script>
  
  <style scoped>
  .blur-gradient-box {
    width: 100%; /* Setze die Breite nach Bedarf */
    height: 100%; /* Setze die Höhe nach Bedarf */
    position: relative;/* Runde Ecken, um eine Kreisform zu erstellen */
    background: linear-gradient(45deg,  var(--primary-color), var(--secondary-color), var(--third-color),  var(--accent-color));
    background-size: 600% 100%;
    animation: gradient 16s linear infinite;
    filter: blur(80%);
    animation-direction: alternate; /* Ermöglicht das Rotieren des Gradienten */
  }
  
  @keyframes gradient {
    0% {background-position: 0%}
    100% {background-position: 100%}
}
  </style>
  
  

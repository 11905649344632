<template>
    <div class="home flex-row">
        <GradientComponent>
            <div class="starter flex-row">
                <div class="title">
                    <h1 style="white-space: nowrap;">Monika Michel</h1>
                    <div class="flex-row align-center">
                        <h4 style="font-weight: normal">B. Sc. </h4>
                        <h2> Wirtschaftsinformatik </h2>
                    </div>
                </div>
            </div>
        </GradientComponent>
    </div>
    <Box id="aboutme" class="m-0">
        <img src="../assets/Monika-M.jpg" class="me">
        <div class="mytext">
            <h3> Ich bin </h3>
            <p class="italic"> Monika, mit einem Hintergrund in Wirtschaftsinformatik und aktuellem Fokus auf
                Medieninformatik. Wenn ich nicht gerade in den Weiten des Studiums versinke, bin ich auf dem Volleyballfeld
                oder genieße die Natur. Besonders fasziniert mich die kreative Vielfalt von UX/UI Design und Webentwicklung,
                weshalb ich mich in dieser Richtung weiterentwickeln möchte. </p>
            <h4>Willkommen auf meiner Website!</h4>
            <Vue3Lottie :animationData="Animation" :speed="1" :loop="2" :height="600" :noMargin="true" @segmentStart="aboutme"/>
        </div>
    </Box>
    <div id="resume">
        <Box>
            <h2 class="header">01 Bildungsweg</h2>
        </Box>
        <ResumeComponent :timelineItems="educationData" cl="var(--third-color)" />
        <Box>
            <h2 class="header">02 Berufserfahrung</h2>
        </Box>
        <ResumeComponent :timelineItems="workData" />
        <Box>
            <h2 class="header">03 Kenntnisse</h2>
        </Box>
        <ResumeComponent :timelineItems="skillData" cl="var(--third-color)" />
        <Box>
            <h2 class="header">04 Interessen</h2>
        </Box>
        <ResumeComponent :timelineItems="intrestData" cl="var(--accent-color)" />
    </div>
</template>

<script setup>
import Box from '../components/BoxesComponent.vue';
import ResumeComponent from '@/components/ResumeComponent.vue';
import GradientComponent from '@/components/GradientComponent.vue';
import Animation from '../assets/animation.json'
import { ref } from 'vue';

const educationData = ref([
    { year: '2015 - 2018', text: 'Ausbildung - Großhandelskauffrau', heading: 'Metro Deutschland GmbH' },
    { year: '2018 - 2019', text: 'Fachabitur - Wirtschaft', heading: 'Berufsoberschule Kelheim' },
    { year: '2019 - 2024', text: 'B. Sc. Wirtschaftsinformatik', heading: 'OTH Regensburg' },
    { year: '2024 - jetzt', text: 'M. Sc. Medieninformatik', heading: 'Universität Regensburg' },
]);

const workData = ref([
    { year: '2021 - 2022', heading: 'Werkstundentin: IT-Support <br> Praktikantin: IT-Projektmanagement (PMO), IT-Support', text: 'Bayernwerk AG' },
    { year: '2022 - 2023', heading: 'Werkstudentin: Softwareentwicklung <br> Bachelorarbeit', text: 'NeuroNet GmbH' },
    { year: '2023 - heute', heading: 'Tutorin: Programmieren 2 <br> Tutorin: IT-Architektur', text: 'OTH Regensburg' },
    { year: '2015 - heute', heading: 'Gastroarbeiten', text: 'versch. Unternehmen' }
]);

const skillData = ref([
    { year: 'Java, JavaScript, HTML/CSS, Vue.js', text: 'Frontend-Entwicklung' },
    { year: 'Scrum, Agile Methoden, Projektplanung, Testing', text: 'Projektmanagement' },
    { year: 'Troubleshooting, Gerätebereitstellung und -installation, Bestandsverwaltung', text: 'IT-Support' },
    { year: 'Deutsch, Englisch, Russisch (Grundkenntnisse)', text: 'Sprachen' },
]);

const intrestData = ref([
    { text: 'UX/UI - Design' },
    { text: 'Webentwicklung' },
    { text: 'Volleyball' },
    { text: 'Networking' },
]);
</script>

<style scoped>
#aboutme {
    width: 100%;
    height: 40rem;
    display: flex;
    flex-direction: row;
}

.home {
    display: flex;
    height: 60rem;
    width: 100%;
}


.mytext {
    display: flex;
    align-items: baseline;
    padding-inline: 2rem;
    flex-direction: column;
}

.italic {
    font-size: 18px;
    text-size-adjust: auto;
}

.animation {
    display: flex;
    align-items: flex-end;
}

.starter {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--natural-color);
    height: 100%;
    width: 100%;
}

.title {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
}

.header {
    padding: 2rem;
    margin-bottom: 0;
}

.me {
    width: 40%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 668px) {
    #aboutme {
        flex-direction: column;
        height: auto;
    }

    .italic {
        font-size: 16px;
    }

    .me {
        width: 100%;
    }

    .header {
        font-size: 2rem;
    }

    .home {
        height: 40rem;
    }
}
</style>

<template>
  <header class="navbar flex-row">
    <nav class="navbar_container right">
      <ul class="navbar_menu" :class="{ 'active': menuOpen }">
        <li class="navbar_item">
          <router-link to="/" :class="{ 'active': $route.path === '/' }" aria-current="$route.path === '/' ? 'page' : undefined">Home</router-link>
        </li>
        <li class="navbar_item">
          <a @click="scrollToSection('aboutme')">Über mich</a>
        </li>
        <li class="navbar_item">
          <a @click="scrollToSection('resume')">Lebenslauf</a>
        </li>
        <li class="navbar_item">
          <a @click="scrollToSection('contact')">Kontakt</a>
        </li>
      </ul>
      <button class="burger-menu" @click="toggleMenu">
        <span></span>
        <span></span>
        <span></span>
      </button>
    </nav>
  </header>
</template>

<script setup>
import { ref } from 'vue';

const menuOpen = ref(false);

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
    menuOpen.value = false; // close menu on click
  }
}

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value;
}
</script>

<style scoped>
.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: transparent;
  z-index: 9999;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.navbar_container {
  align-items: center;
  padding: 1rem 4rem;
}

.navbar_container.right {
  flex: 1;
  background-color: transparent;
}

.navbar_menu {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 0;
  padding: 0;
}

.navbar_item {
  margin-left: 2rem;
  text-transform: uppercase;
  font-size: 1rem;
}

.navbar_item a {
  color: var(--natural-color);
  text-decoration: none;
  padding: 1rem 0;
  display: block;
}

.navbar_item a:hover,
.navbar_item a.active {
  color: var(--primary-color);
}

.burger-menu {
  display: none; /* initially hide burger menu */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-right: 1rem;
}

.burger-menu span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: var(--natural-color);
  margin-bottom: 6px;
  transition: transform 0.3s ease;
}

@media (max-width: 600px) {
  .navbar_container {
    flex-direction: column;
    align-items: flex-end;
  }

  .navbar_menu {
    display: none; /* hide navbar menu items by default */
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%; /* position below the header */
    left: 0;
    background-color: var(--third-color); /* background color of the dropdown */
    box-shadow: 0px 8px 16px rgba(0,0,0,0.1); /* optional: shadow for dropdown */
    z-index: 999; /* ensure dropdown is above other content */
  }

  .navbar_menu.active {
    display: flex; /* show navbar menu when active */
  }

  .navbar_item {
    margin: 0;
    width: 100%;
    font-size: 14px;
  }

  .navbar_item a {
    text-align: left;
    padding: 1rem;
  }

  .burger-menu {
    display: block; /* show burger menu button */
  }
}
</style>

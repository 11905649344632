<template>
  <div class="cv">
    <div class="container">
      <ul class="timeline" >
        <li v-for="(item, index) in timelineItems" :key="index" :ref="setTimelineRef"
          :style="{ transitionDelay: `${index * 0.2}s`, left: `${index * 10}%`, color: cl}" >
              <h4 class="m-0">{{ item.text }}</h4>
              <p class="sub m-0"  v-html="item.heading"></p>
              <h5 class="m-0 year" >{{ item.year }}</h5>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from 'vue';

defineProps({
  cl: String,
  timelineItems: {
    type: Array,
    required: true,
    default: () => ([
      { year: 'Jahr', heading: 'Überschrift', text: 'Text' },
    ])
  }
});

const timelineRefs = ref([]);
let observer;

const setTimelineRef = el => {
  if (el) {
    timelineRefs.value.push(el);
  }
};

onMounted(() => {
  const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
  };

  observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('visible');
        observer.unobserve(entry.target);
      }
    });
  }, observerOptions);

  timelineRefs.value.forEach(el => {
    observer.observe(el);
  });
});

onUnmounted(() => {
  if (observer) {
    timelineRefs.value.forEach(el => {
      observer.unobserve(el);
    });
  }
});
</script>

<style scoped>
.cv {
  height: auto;
  width: 100%;
}



.container {
  max-width: 90%;
  margin: 50px auto;
  margin-top: 0;
}

.sub {
  margin-top: 12px;
  font-size: 20px;
  color: var(--text-color);
}

.year {
  color: var(--text-color);
}
.timeline {
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
}

.timeline li {
  border-bottom: 4px groove var(--text-color);
  color: var(--secondary-color);
  position: relative;
  width: 70%;
  margin-bottom: 10px;
  padding: 30px 10px;
  text-align: left;
  transition: opacity 0.6s ease, transform 2s ease;
  opacity: 0;
  transform: translateX(-20%);
  left: 0;
}
.timeline p {
  line-height: 28px;
}

.timeline li.visible {
  opacity: 1;
  transform: translateX(0);
}

@media (max-width: 768px) {
  .timeline li {
    width: 100%;
    left: 0 !important;
  }

  .sub{
    font-size: 18px;
  }
}
</style>
